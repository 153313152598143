<template>
  <div class="nft-card-list">
    <div
      class="list grid xl:grid-cols-4 md:grid-cols-2 grid-cols-2 gap-12 w-full border border-gray-300"
    >
      <div
        class="justify-self-center z-30 w-full"
        v-for="card in data"
        :key="`nft-card-list-${card.id}`"
      >
        <CardItem :data="card"></CardItem>
      </div>
    </div>
    <Pagination
      class="text-center mt-24  mb-24"
      :total="numberOfCard"
      :pageSize="pageSize"
      :current="currentPage"
      @onChange="onChange"
      :showQuickJumper="false"
    ></Pagination>
    <!-- <div class="text-center mt-8">
      <a
        target="_blank"
        href="https://forms.gle/tPJ1NLKTc1tyPozZ6"
        class="font-medium border border-white whitespace-nowrap xxs:text-3xl text-2xl bg-transparent rounded-lg xxs:px-16 px-4 py-2 text-white h-18"
        >Apply for publication</a
      >
    </div> -->
  </div>
</template>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<script>
import { PICTURE_PER_PAGE } from '@/constants/common.constant';
import Pagination from '@/components/common/Pagination.vue';
import CardItem from '@/components/series/SeriesCardItem/index.vue';
//import { mapState } from 'vuex';
// ------+---------+---------+---------+---------+---------+---------+---------E
export default {
  name: 'SeriesCardList',
  // ----+---------+---------+---------+---------+---------+---------+---------E
  components: {
    CardItem,
    Pagination,
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  props: {
    // テーブル categories から抽出した、当該プロジェクト（漫画家、アーティスト）
    // 配下のシリーズ一ページ分のレコードオブジェクトの配列。
    data: {
      type: Array,
      default: () => [],
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    // テーブル categories から抽出した、当該プロジェクト（漫画家、アーティスト）
    // 配下の全シリーズのレコードの数。
    numberOfCard: {
      type: Number,
    },
  }, // End of props: {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  data() {
    return {
      pageSize: PICTURE_PER_PAGE,
      currentPage: 1,
    };
  }, // End of data() {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  //computed: {
  //  ...mapState({
  //    total: (state) => state.marketplace.totalItems || 0,
  //  }),
  //},
  // ----+---------+---------+---------+---------+---------+---------+---------E
  methods: {
    onChange(page) {
      this.currentPage = page;
      this.$emit('onPageChange', page);
    },
  }, // End of methods: {...}
}; // End of export default {...}
</script>

<style>
.list{
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 30px;
  padding-right: 30px;
  @media (max-width:798px){
    padding-left: 5px;
    padding-right: 5px;
  }
}
</style>
