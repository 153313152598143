<template>
  <section class="custom-background pb-12 overflow-x-hidden">
    <a-spin :spinning="loading">
      <div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓ ヘッダ直下の画像 ↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓ ＰＣのような幅 640 px 以上の画面で表示 ↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <div  class="hidden sm:block">
          <div class="mx-auto w-8/12 z-30 relative">
            <div class="w-full h-full object-scale-down flex items-center justify-center">
              <img
                class="rounded-3xl cursor-pointer"
                :src="creator.banner_url"
                @click="goToLinkPlace()"
              />
            </div>
          </div>
        </div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑ ＰＣのような幅 640 px 以上の画面で表示 ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓ スマートフォンのような幅 640 px 未満の画面で表示 ↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <div  class="visible sm:hidden">
          <div class="mx-auto w-full z-30 relative">
            <div class="w-full h-full object-scale-down flex items-center justify-center">
              <img
                class="cursor-pointer"
                :src="creator.banner_url"
                @click="goToLinkPlace()"
              />
            </div>
          </div>
        </div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑ スマートフォンのような幅 640 px 未満の画面で表示 ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑ ヘッダ直下の画像 ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- プロジェクトについて -----+---------+---------+---------+------ -->
        <!--
        <div class="description width-1140-no-padding py-2 mx-auto mb-4">
          <div class="title custom-title">{{ $t('PROJECT_ABOUT_PROJECT_SECTION_TITLE') }}</div>
        </div>
        -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- プロジェクトについててＨＴＭＬテキストで説明 -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <div
              v-if="localizedDescription"
              v-html="$sanitize(localizedDescription)"
              class="width-1140-no-padding py-2 px-4 mx-auto mt-8 mb-4 text-lg text-black description-CSS"
        ></div>
        <!-- シリーズ表示の場合と、ＮＦＴ表示の場合がある -+---------+------ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓ show_nftlist＝0 でシリーズ CardList 一ページ分 12 枚表示 ↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <div v-if="creator.show_nftlist == 0">
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- 「シリーズ一覧」セクションタイトル -->
          <!--  show_nftlist==0 でシリーズ CardList 一ページ分 12 枚表示 --- -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <div class="description width-1140-no-padding py-2 mx-auto">
              <div class="title custom-title"><!-- シリーズ一覧 -->
                  {{ $t('PROJECT_SERIES_LIST_SECTION_TITLE') }}
              </div>
          </div>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↓ プロジェクト配下にシリーズが有る場合、シリーズ一覧 ↓ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <div
            v-if="series && series.length > 0"
            class="mx-auto width-1140"
          >
            <CardListSeries
              @onPageChange="onPageChange"
              :data="series"
              :numberOfCard="numberOfSeries"
            ></CardListSeries>
          </div>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↑ プロジェクト配下にシリーズが有る場合、シリーズ一覧 ↑ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- プロジェクト配下にシリーズが無い場合 -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <div
            v-else
            class="comingsoon"
          >coming soon..</div>
        </div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑ show_nftlist＝0 でシリーズ CardList 一ページ分 12 枚表示 ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ------+---------+---------+---------+---------+---------+-----  -->
        <!-- ↓ show_nftlist≠0 ＮＦＴ一ページ分表示 ↓ -->
        <!-- ------+---------+---------+---------+---------+---------+-----  -->
        <div v-else>
          <!-- ----+---------+---------+---------+---------+---------+-----  -->
          <!-- 「NFT一覧」セクションタイトル -->
          <!-- show_nftlist!=0 ＮＦＴ一ページ分表示 -->
          <!-- ----+---------+---------+---------+---------+---------+-----  -->
          <div class="description width-1140-no-padding py-2 mx-auto">
            <div class="title custom-title">
              {{ $t('PROJECT_NFT_LIST_SECTION_TITLE') }} <!-- ＮＦＴ一覧 -->
            </div>
          </div>
          <!-- ----+---------+---------+---------+---------+---------+-----  -->
          <!-- ↓ NFT一覧 ↓ -->
          <!-- ----+---------+---------+---------+---------+---------+-----  -->
          <div
            class="mx-auto width-1140"
          >
            <CardListNFT
              @onPageChange="onPageChange"
              :data="list"
            ></CardListNFT>
          </div>
          <!-- ----+---------+---------+---------+---------+---------+-----  -->
          <!-- ↑ NFT一覧 ↑ -->
          <!-- ----+---------+---------+---------+---------+---------+-----  -->
        </div>
        <!-- ------+---------+---------+---------+---------+---------+-----  -->
        <!-- ↑ show_nftlist≠0 ＮＦＴ一ページ分表示 ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+-----  -->
      </div>
    </a-spin>
  </section>
</template>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<script>
// １ページに表示する画像数。
import { PICTURE_PER_PAGE     } from '@/constants/common.constant';
// シリーズ一覧表示用。
import CardListSeries       from '@/components/series/SeriesCardList/index.vue';
// NFT一覧表示用。
import CardListNFT              from '@/components/nft/CardList/index.vue';
import { mapActions, mapState } from 'vuex';
import { getCategories        } from '@/services/categories';
// ======+=========+=========+=========+=========+=========+=========+=========E
export default {
  name: 'page-creator',
  // ====+=========+=========+=========+=========+=========+=========+=========E
  components: { CardListSeries, CardListNFT, },
  // ====+=========+=========+=========+=========+=========+=========+=========E
  data() {
    return {
      id: this.$route.params.id,
      loading: false,
      creator: '',
      NFTAll: [], // クリエイター配下の全ＮＦＴ
      series: [],
      numberOfSeries: 0,
    }; // End of return {...}
  }, // End of data() {...}
  // ====+=========+=========+=========+=========+=========+=========+=========E
  metaInfo: {
    title: 'BCG・GameFi 専門のNFT1次販売ローンチパッド - Zaif INO',
    // meta: [
    //     {
    //         vmid: "description",
    //         property: "description",
    //         content:
    //             "ブロックチェーンゲーム・GameFi専門のNFT1次販売ローンチパッドのZaif INO。事前審査制導入で取り扱いNFTのクオリティを担保し、パブリックチェーンで海外プロジェクトの取り扱いも行います。",
    //     },
    //     {
    //         property: "og:title",
    //         content: "BCG・GameFi 専門のNFT1次販売ローンチパッド - Zaif INO",
    //         vmid: "og:title",
    //     },
    // ],
  }, // End of metaInfo: {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  computed: {
    ...mapState({
      // store にセットした、言語モード（日本語／英語）。
      locale: (state) => state?.setting.locale,
      // store にセットした、マーケットプレイスのＮＦＴ情報。
      list: (state) => state.marketplace.marketplaceList,
      //total: (state) => state.marketplace.totalItems || 1,
      // パンくずリストをヘッダに書くためのコード。現在未使用。
      hierarchy: (state) => state.hierarchy.hierarchy,
      name_en  : (state) => state.hierarchy.name_en,
    }), // End of ...mapState({...})
    // --+---------+---------+---------+---------+---------+---------+---------E
    localizedDescription() {
      const result = (this.locale === 'en'  ? this.creator.description_en
                                            : this.creator.description_ja
      );
      return result;
    }, // End of localizedDescription() {...}
  }, // End of computed: {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  async mounted() {
    // ヘッダのカテゴリ選択コンボボックスと検索テキストボックスを初期化。
    this.clearHeaderCondition();
    // クリエイター情報取得。
    await this.getCreator(this.$route.params.id);
    if (this.creator.show_nftlist == 0) {
      await this.getSeries(1); 
    } else {
      await this.fetchNewPage(undefined, 1);
    } // End of if (this.creator.show_nftlist == 0) {...} else {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    // パンくずリストをヘッダに書くためのコード。現在未使用。
    this.setHierarchy({hierarchy: this.creator.hierarchy,
                         name_en: this.creator.name_en,
                         name_ja: this.creator.name_ja,
    });
  }, // End of async mounted() {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  methods: {
    ...mapActions({
      // store にある、ヘッダのカテゴリコンボボックスと検索テキストボックスの
      // 値をクリア。
      clearHeaderCondition: 'extractcondition/clearHeaderCondition',
      // store にマーケットプレイスのＮＦＴ情報をセット。
      fetchMarketplace: 'marketplace/fetchMarketplaceList',
      // パンくずリストをヘッダに書くためのコード。現在未使用。
      setHierarchy: 'hierarchy/setHierarchy',
    }), // End of ...mapActions({...})
    // --+---------+---------+---------+---------+---------+---------+---------E
    // ページが切り替わったら実行。下記の二つの場合があり、処理が異なる。
    //   ・show_nftlist=0のシリーズ表示
    //   ・show_nftlist=1のＮＦＴ表示。
    async onPageChange(page) {
      if (this.creator.show_nftlist == 0) {
        await this.getSeries(page);
      } else {
        await this.fetchNewPage(undefined, page);
      } // End of if (this.creator.show_nftlist == 0) {...} else {...}
    }, // End of async onPageChange(page) {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    // バックエンド mint-nft-backend DB テーブル categories からクリエイター情報を
    // 取得し this.creator に取り込む。内容は一オブジェクト。
    // 本ファイルでは、当該クリエイター id から、一人分の情報を取得するのみ。
    async getCreator(id) {
      const {total: total, items: items} = await getCategories({
        type:  1,
        id  : id,
      });
      if (total==0) {
        // データが取得できなかったらエラーページに遷移。
        await this.$router.push(`/ERROR_PAGE`)
      } 
      this.creator = items[0];
    }, // End of async getCreator(objectAboutCreator) {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    // バックエンド mint-nft-backend DB テーブル categories から
    // 当該プロジェクト（漫画家、アーティスト）のシリーズ情報（オブジェクト）を
    // 一ページ分取得し this.series に取り込む。
    async getSeries(page) {
      const objectAboutSeries = {
        type  : 2, // テーブル categories のシリーズレコードを指定。
        limit : PICTURE_PER_PAGE, // 取得シリーズ数。現在 12。
        offset: PICTURE_PER_PAGE * (page - 1), // １ページなら0、２ページなら12。
        hierarchy: this.creator.hierarchy + '\\' + this.creator.name_en,
        is_deleted: 0, // 論理削除されていないシリーズのみ取得。
      }; // End of const objectAboutSeries = {...}
      // ----------+---------+---------+---------+---------+---------+---------E
      const {total: total, items: items} =
          await getCategories(objectAboutSeries);
      this.numberOfSeries = total;
      this.series = items;
    }, // End of async getSeries(page) {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    localizedName(item) {
      const result = (this.locale === 'en'  ? item.name_en : item.name_ja);
      return result;
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    // ＮＦＴを１ページ分取得。
    async fetchNewPage(status, page) {
      try {
        // alert(`*** START: fetchNewPage: creator.page.vue ***`);
        this.loading = true;
        const offset = PICTURE_PER_PAGE * (page - 1);
        await this.fetchMarketplace({
          offset   : offset,
          limit    : PICTURE_PER_PAGE,
          // '\' は特殊文字で '\\' としないとうまくいかない。
          hierarchy: JSON.stringify([
            this.creator.hierarchy + '\\' + this.creator.name_en, 
            this.creator.hierarchy + '\\' + this.creator.name_en + '\\%',
          ]),
        }); // End of await this.fetchMarketplace({...})
        this.loading = false;
        // alert(`*** END  : fetchNewPage: creator.page.vue ***`);
      } catch(error) {
        alert(error);
      } // End of try {...} catch(error) {...}
    }, // End of async fetchNewPage(status, page) {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    // this.creator.link の URL を開く。一番上の画面をクリックしたときの処理。
    goToLinkPlace() {
      // window.open(this.creator.link, '_blank');
    },
  }, // End of methods: {...}
}; // End of export default {...}
</script>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<style
  scoped
  lang="scss"
>
.custom-background {
    background-color: white;
}

::v-deep .creator-detail-page {
  @apply overflow-hidden;
  @apply px-4;
  @screen md {
    @apply px-4;
  }
  @screen xl {
    width: 1140px;
    @apply px-0;
  }
  .description {
    a {
      color: #40a9ff;
    }
  }
  &-breadcrumb {
    .ant-breadcrumb {
      span {
        .ant-breadcrumb-link {
          @apply text-white;
          @apply font-medium;
        }
        .ant-breadcrumb-separator {
          color: #fff;
        }
      }
    }
  }
  .combined-target-bar {
    @screen xxs {
      padding-left: 16px;
      padding-right: 16px;
    }
    @screen md {
      padding-left: 32px;
      padding-right: 32px;
    }
    @apply px-1;
    @apply mb-4;
  }
}


.width-1140 {
  @apply px-2;
  @screen md {
    @apply px-4;
  }
  @screen xl {
    width: 1140px;
    @apply px-0;
  }
}

.width-1160 {
  @apply px-2;
  @screen md {
    @apply px-4;
  }
  @screen xl {
    width: 1140px;
    @apply px-0;
  }
}

.width-1140-no-padding {
  @screen xl {
    width: 1140px;
  }
}
.width-1240-no-padding {
  @screen xl {
    width: 1240px;
  }
}
.comingsoon {
  display: flex;
  align-items: center;
  justify-content: center; /* 横方向の中央揃え */
  color:black;
  font-size:24px;
 }

.text-top {
  @apply text-2xl;
  @screen xxs {
    @apply text-3xl;
  }
  @screen md {
    @apply text-base;
  }
}
  /* タイトル用の基本スタイル */
  .title {
  display: block;
  font-size: 24px; /* フォントサイズを大きく */
  font-weight: bold; /* フォントを太字に */
  /* color: #FFFFFF; テキストカラー 白 */
  color: #000000; /* テキストカラー 黒 */
  margin-bottom: 20px; /* 下余白 */
  padding: 10px 10px; /* 上下パディング */
}

/* 特定のセクション用のカスタムスタイル */
.custom-title {
  border-radius: 8px; /* 角の丸み */
  text-align: left; /* テキストを中央揃えに */
}

::v-deep .ant-breadcrumb {
  span {
    .ant-breadcrumb-link {
      @apply text-white;
      @apply font-medium;
    }
  }
}
::v-deep .ant-select {
  color: #fff;
  @apply font-semibold;
  &-arrow {
    color: white;
  }
  &-selection {
    background-color: transparent;
    border: 1px solid white;
    @apply rounded-xl;
    @apply w-32 h-6;
    @apply text-xs;
    &__rendered {
      @apply mx-0;
      @apply w-full h-full;
      .ant-select-selection-selected-value {
        @apply w-full h-full text-center relative -top-1;
      }
    }
  }
}
/*以下カテゴリ紹介文内のCSSに適用 */
::v-deep .description-CSS p {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
}

::v-deep .description-CSS img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
}

::v-deep .description-CSS a > img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
}
@media (max-width: 768px) {
  ::v-deep .description-CSS p {
    max-width: 100%;
  }
}
</style>
